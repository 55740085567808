import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import { Button, Heading6, Icon } from "@material-tailwind/react";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import Loader from "components/Loader";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFlashMessages } from "actions/flashMessagesAction";
import { useEffect } from "react";
import FlashMessageList from "components/flash/FlashMessageList";
import { resetErrors } from "actions/flashMessagesAction";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { archive_reseller } from "actions/adminResellerAction";
import { send_credentials } from "actions/adminResellerAction";

export default function ResellerCardTable(props) {
  const { flash } = useSelector((state) => state.flash);
  const errorMsg = useSelector((state) => state.errors);
  const [flashMsg, setFlashMsg] = useState([]);
  const [tableView, setTableView] = useState("0");
  const [errorAlert, setErrorAlert] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [modalContent, setModalContent] = useState("");

  const dispatch = useDispatch();

  const clearFlash = () => {
    setTimeout(() => {
      dispatch(clearFlashMessages());
      setFlashMsg([]);
    }, 8000);
  };
  useEffect(() => {
    dispatch(resetErrors());
    if (flash && flash.length > 0) {
      setFlashMsg(flash);
      clearFlash();
    }
    if (errorMsg.message) {
      setErrorAlert(errorMsg.message);
    }
  }, [flash, errorMsg.message, setErrorAlert]);

  function handleChange(e) {
    setTableView(e.target.value);
  }

  const archiveNow = ({ reseller_id, val }) => {
    dispatch(archive_reseller({ id: reseller_id, is_archived: val }));
  };
  const { resellers, loading, name, link } = props;

  // const SendCredentials = (data) => {
  //   dispatch(send_credentials(data));
  // };

  function previewUser(id) {
    setModalContent(
      resellers
        .filter((v) => v.id === id)
        .map((item) => (
          <div key={item.id}>
            <p className={"flexIt"}>
              <span>Name:</span> <span>{item.name}</span>
            </p>
            <p className={"flexIt"}>
              <span>Email:</span> <span>{item.email}</span>
            </p>
            <p className={"flexIt"}>
              <span>Phone:</span> <span>{item.phone}</span>
            </p>
            <p className={"flexIt"}>
              <span>Address:</span> <span>{item.address}</span>
            </p>
            <p className={"flexIt"}>
              <span>State:</span> <span>{item.state}</span>
            </p>
            <p className={"flexIt"}>
              <span>City:</span> <span>{item.city}</span>
            </p>
            <p className={"flexIt"}>
              <span>Status:</span> <span>{item.status}</span>
            </p>
            <p className={"flexIt"}>
              <span>Created At:</span>{" "}
              <span>
                {moment(item.created_at).format("MM/DD/YYYY hh:mm A")}
              </span>
            </p>
          </div>
        ))
    );
    setShowModal(true);
  }

  let setContent;
  if (loading) {
    setContent = (
      <tr>
        <td colSpan="6">
          <Loader />
        </td>
      </tr>
    );
  } else if (resellers.length === 0) {
    setContent = (
      <tr>
        <th
          colSpan="6"
          className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-center"
        >
          No records found.
        </th>
      </tr>
    );
  } else {
    setContent = resellers
      .filter((v) =>
        tableView !== "2"
          ? typeof v.is_archived !== "undefined" &&
            v.is_archived.toString() === tableView
          : v
      )
      .map((item) => (
        <tr key={item.id}>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.name}
          </th>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.email && item.email}
          </th>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.phone}
          </th>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {moment(item.created_at).format("MM/DD/YYYY hh:mm A")}
          </th>
          <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            {item.status === "active" ? (
              <span style={{ color: "green", fontStyle: "italic" }}>
                {item.status}
              </span>
            ) : (
              <span style={{ color: "red", fontStyle: "italic" }}>
                {item.status}
              </span>
            )}
          </th>
          <th className="flex border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
            <NavLink
              to={`/master/reseller/edit/${item.id}`}
              className="mr-1 false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-full w-8 h-8 p-0 grid place-items-center text-xs leading-normal text-white bg-blue-500 hover:bg-blue-700 focus:bg-blue-400 active:bg-blue-800 shadow-md-blue hover:shadow-lg-blue"
              title="Edit Reseller"
            >
              <Icon name="edit" size="sm" color="white" />
            </NavLink>
            <Button
              color="orange"
              buttonType="filled"
              size="sm"
              rounded={true}
              block={false}
              iconOnly={true}
              ripple="light"
              onClick={(e) => previewUser(item.id)}
              title="Preview Reseller"
            >
              <Icon name="face" size="sm" />
            </Button>
            {item.is_archived.toString() === "0" ? (
              <Button
                color="red"
                buttonType="filled"
                className="ml-1"
                size="sm"
                rounded={true}
                block={false}
                iconOnly={true}
                ripple="light"
                onClick={() => archiveNow({ reseller_id: item.id, val: "1" })}
                title="Archive User"
              >
                <Icon name="archive" size="sm" />
              </Button>
            ) : (
              <Button
                color="yellow"
                buttonType="filled"
                className="ml-1"
                size="sm"
                rounded={true}
                block={false}
                iconOnly={true}
                ripple="light"
                onClick={() => archiveNow({ reseller_id: item.id, val: "0" })}
                title="Unarchive User"
              >
                <Icon name="unarchive" size="sm" />
              </Button>
            )}
            {/* <Button
              color="orange"
              buttonType="filled"
              size="sm"
              className="ml-1"
              rounded={true}
              block={false}
              iconOnly={true}
              ripple="light"
              onClick={(e) =>
                SendCredentials({ email: item.email, name: item.name })
              }
              title="Send Reseller's Credentials"
            >
              <Icon name="key" size="sm" />
            </Button> */}
          </th>
        </tr>
      ));
  }

  return (
    <Card>
      <CardHeader color="orange" contentPosition="left" className={"flexIt"}>
        <h2 className="text-white text-2xl">{name}</h2>
        <div style={{ fontSize: "17px", fontStyle: "italic" }}>
          <input
            type="radio"
            name="view"
            value="2"
            style={{
              height: "18px",
              width: "18px",
              verticalAlign: "text-top",
              cursor: "pointer",
            }}
            checked={tableView === "2"}
            onChange={handleChange}
          />
          <span style={{ marginRight: "10px" }}>All</span>
          <input
            type="radio"
            name="view"
            value="1"
            style={{
              height: "18px",
              width: "18px",
              verticalAlign: "text-top",
              cursor: "pointer",
            }}
            checked={tableView === "1"}
            onChange={handleChange}
          />
          <span style={{ marginRight: "10px" }}>Archived</span>
          <input
            type="radio"
            name="view"
            value="0"
            style={{
              height: "18px",
              width: "18px",
              verticalAlign: "text-top",
              cursor: "pointer",
            }}
            checked={tableView === "0"}
            onChange={handleChange}
          />
          <span style={{ marginRight: "10px" }}>Unarchived</span>
        </div>
        {link && (
          <Link
            className="false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-2.5 px-6 text-xs leading-normal bg-transparent border border-solid shadow-none text-white-500 border-white-500 hover:bg-white-50 hover:border-white-700 hover:text-white-700 hover:bg-white-50 active:bg-white-100"
            to={link}
          >
            Add New
          </Link>
        )}
      </CardHeader>
      <CardBody>
        <div className="overflow-x-auto">
          {flashMsg && (
            <FlashMessageList
              messages={
                Array.isArray(flashMsg)
                  ? flashMsg.filter(
                      (f) =>
                        f.eventFor === "reseller_update" ||
                        f.eventFor === "reseller_create"
                    )
                  : {}
              }
            />
          )}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr key="head">
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Name
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Email
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Phone
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Created At
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Status
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{setContent}</tbody>
          </table>
        </div>
      </CardBody>
      <Modal
        size="regular"
        active={showModal}
        toggler={() => setShowModal(false)}
      >
        <ModalHeader toggler={() => setShowModal(false)}>
          Preview Reseller
        </ModalHeader>
        <ModalBody>
          <div
            className="text-base leading-relaxed text-gray-600 font-normal"
            style={{ width: "300px" }}
          >
            {modalContent}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="red"
            buttonType="link"
            onClick={(e) => setShowModal(false)}
            ripple="dark"
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
}
