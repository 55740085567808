import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import Input from "@material-tailwind/react/Input";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlashMessageList from "components/flash/FlashMessageList";
import Loader from "components/Loader";
import { resetErrors } from "actions/flashMessagesAction";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { create_embed_member } from "actions/memberActions";
import { clearFlashMessages } from "actions/flashMessagesAction";

const CreateMemberEmbeddForm = () => {
  const { res_id } = useParams();
  let history = useHistory();

  const { loading } = useSelector((state) => state.members);
  const errorsSubmission = useSelector((state) => state.errors);
  const { flash } = useSelector((state) => state.flash);
  const [flashMsg, setFlashMsg] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [planId, setPlanId] = useState("white_label");
  const [planType, setPlanType] = useState("annually");
  const [planAmt, setPlanAmt] = useState("828.00");
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const { isResellerAuthenticated } = useSelector((state) => state.auth);
  const clearFlash = () => {
    setTimeout(() => {
      dispatch(clearFlashMessages());
      setFlashMsg([]);
    }, 8000);
  };

  useEffect(() => {
    if (errorsSubmission) {
      setErrors(errorsSubmission);
    }
  }, [errorsSubmission, dispatch]);

  useEffect(() => {
    dispatch(resetErrors());
    if (flash && flash.length > 0) {
      setFlashMsg(flash);
      clearFlash();
    }
  }, [flash]);

  let loader;
  if (loading) {
    loader = <Loader />;
  }

  const saveForm = async (e) => {
    e.preventDefault();
    const old_res = res_id;
    const data = {
      res_id: res_id ? atob(res_id) : "",
      firstName,
      lastName,
      email,
      username,
      password,
      phone,
      address,
      city,
      state,
      zip,
      planType,
      planId,
    };

    dispatch(create_embed_member(data, history, old_res));
  };

  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 embeddTransY mb-4">
      <Card className="mb-5">
        <CardHeader color="purple" contentPosition="none">
          <div className="w-full flex items-center justify-between">
            <h2 className="text-white text-2xl">User SignUp Form</h2>
            {isResellerAuthenticated && (
              <Link
                to="/users"
                style={{
                  padding: "5px 15px",
                  border: "1px solid",
                  borderRadius: "5px",
                }}
              >
                All Users
              </Link>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <form onSubmit={saveForm}>
            <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
              User Information
            </h6>
            <div className="flex flex-wrap mt-10">
              <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                <Input
                  type="text"
                  color="purple"
                  placeholder="First Name"
                  value={firstName && firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  error={
                    typeof errors.errors !== "undefined" &&
                    typeof errors.errors.firstName !== "undefined"
                      ? errors.errors.firstName[0]
                      : ""
                  }
                />
              </div>
              <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                <Input
                  type="text"
                  color="purple"
                  placeholder="Last Name"
                  value={lastName && lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="w-full lg:w-6/12 mb-10 font-light">
                <Input
                  type="text"
                  color="purple"
                  placeholder="Username"
                  value={username && username}
                  onChange={(e) => setUsername(e.target.value)}
                  error={
                    typeof errors.errors !== "undefined" &&
                    typeof errors.errors.username !== "undefined"
                      ? errors.errors.username[0]
                      : ""
                  }
                />
                <p
                  className="pt-2 mt-3"
                  style={{ color: "red", fontSize: "13px" }}
                >
                  Useful in case you decide to migrate this user to clpapp.
                </p>
              </div>
              <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                <Input
                  color="purple"
                  placeholder="Password"
                  type="password"
                  value={password && password}
                  error={
                    typeof errors.errors !== "undefined" &&
                    typeof errors.errors.password !== "undefined"
                      ? errors.errors.password[0]
                      : ""
                  }
                  onChange={(e) => setPassword(e.target.value)}
                />
                <p
                  className="pt-2 mt-3"
                  style={{ color: "red", fontSize: "13px" }}
                >
                  Useful in case you decide to migrate this user to clpapp.
                </p>
              </div>
              <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                <Input
                  type="email"
                  color="purple"
                  placeholder="Email Address"
                  value={email && email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={
                    typeof errors.errors !== "undefined" &&
                    typeof errors.errors.email !== "undefined"
                      ? errors.errors.email[0]
                      : ""
                  }
                />
              </div>
            </div>

            <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
              Contact Information
            </h6>
            <div className="flex flex-wrap mt-10">
              <div className="w-full lg:w-12/12 mb-10 font-light">
                <Input
                  type="text"
                  color="purple"
                  placeholder="Address"
                  value={address && address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="w-full lg:w-3/12 pr-4 mb-10 font-light">
                <Input
                  type="text"
                  color="purple"
                  placeholder="Phone"
                  value={phone && phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="w-full lg:w-3/12 px-4 mb-10 font-light">
                <Input
                  type="text"
                  color="purple"
                  placeholder="City"
                  value={city && city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
                <Input
                  type="text"
                  color="purple"
                  placeholder="State"
                  value={state && state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
                <Input
                  type="text"
                  color="purple"
                  placeholder="Zip"
                  value={zip && zip}
                  onChange={(e) => setZip(e.target.value)}
                />
              </div>
            </div>
            <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
              Plan Information
            </h6>
            <div className="flex flex-wrap mt-10">
              <div className="w-full lg:w-6/12 mb-10 font-light">
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="inlineRadioOptions"
                      checked={planType === "monthly"}
                      value="monthly"
                      onChange={(e) => {
                        setPlanType(e.target.value);
                        setPlanAmt("89.00");
                      }}
                    />

                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="inlineRadio10"
                    >
                      Monthly
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="inlineRadioOptions"
                      value="annually"
                      checked={planType === "annually"}
                      onChange={(e) => {
                        setPlanType(e.target.value);
                        setPlanAmt("828.00");
                      }}
                    />

                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="inlineRadio20"
                    >
                      Annually
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-12/12 mb-10 font-light">
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="sub_plan"
                      value="white_label"
                      checked="true"
                      onChange={(e) => setPlanId(e.target.value)}
                    />

                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="inlineRadio20"
                    >
                      White Label (${planAmt})
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <Button
              color="purple"
              buttonType="filled"
              size="regular"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="light"
            >
              Submit
            </Button>
            {loader && loader}
            {flashMsg && (
              <FlashMessageList
                messages={
                  Array.isArray(flashMsg)
                    ? flashMsg.filter(
                        (f) =>
                          f.eventFor === "member_update" ||
                          f.eventFor === "member_create"
                      )
                    : {}
                }
              />
            )}
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateMemberEmbeddForm;
