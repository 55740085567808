import { useState } from "react";
import { NavLink } from "react-router-dom";
import AdminNavbar from "../AdminNavbar";
import Icon from "@material-tailwind/react/Icon";
import H6 from "@material-tailwind/react/Heading6";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "actions/adminAuthActions";
import { Button } from "@material-tailwind/react";

export default function SidebarAdmin({ history }) {
  const [showSidebar, setShowSidebar] = useState("-left-64");
  const { isAuthenticated } = useSelector((state) => state.adminAuth);
  const dispatch = useDispatch();

  const logoutNow = () => {
    dispatch(logout(history));
  };

  return (
    <>
      <AdminNavbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div
        className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
      >
        <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
          <a
            href="/dashboard"
            target="_blank"
            rel="noreferrer"
            className="mt-2 text-center w-full inline-block"
          >
            <H6 color="gray">CLG ADMINISTRATOR</H6>
          </a>
          <div className="flex flex-col">
            <hr className="my-4 min-w-full" />

            <ul className="flex-col min-w-full flex list-none">
              <li className="rounded-lg mb-4">
                <NavLink
                  to="/master/dashboard"
                  exact
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="dashboard" size="2xl" />
                  Dashboard
                </NavLink>
              </li>

              <li className="rounded-lg mb-2 ">
                <NavLink
                  to="/master/users"
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="toc" size="2xl" />
                  Users
                </NavLink>
              </li>
              <li className="rounded-lg mb-2 text-gray-700">
                <NavLink
                  to="/master/resellers"
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="map" size="2xl" />
                  Resellers
                </NavLink>
              </li>
              <li className="rounded-lg mb-2 text-gray-700">
                <NavLink
                  to="/master/payments"
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="map" size="2xl" />
                  Payment Log
                </NavLink>
              </li>
              {isAuthenticated && (
                <li className="rounded-lg mb-2 text-gray-700">
                  <Button
                    color="purple"
                    buttonType="filled"
                    size="regular"
                    rounded={false}
                    block={true}
                    iconOnly={false}
                    ripple="light"
                    onClick={logoutNow}
                  >
                    <Icon name="logout" size="sm" /> Logout
                  </Button>
                </li>
              )}
            </ul>

            <ul className="flex-col min-w-full flex list-none absolute bottom-0">
              {/* <li className="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 px-4 rounded-lg text-white mb-2">
                <a
                  href="https://material-tailwind.com/documentation/quick-start"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-4 text-sm font-light py-3"
                >
                  <Icon name="description" size="2xl" />
                  Documentation
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
