import { FILTER_BY_ARCHIVE_STATUS } from "actions/actionTypes";
import { ADMIN_DELETE_MEMBER } from "actions/actionTypes";
import { GET_MEMBER_CLG } from "actions/actionTypes";
import { ADMIN_LIST_ALL_MEMBERS } from "actions/actionTypes";
import { GET_MEMBER } from "actions/actionTypes";
import { MEMBER_LOADING } from "actions/actionTypes";

const { UPDATE_MEMBER } = require("actions/actionTypes");
const { DELETE_MEMBER } = require("actions/actionTypes");
const { CREATE_MEMBER } = require("actions/actionTypes");
const { LIST_ALL_MEMBERS } = require("actions/actionTypes");

const initialState = {
  members: [],
  adminMembers: [],
  member: [],
  memberClg: {},
  loading: false,
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ALL_MEMBERS:
      return {
        ...state,
        members: action.payload,
        loading: false,
      };
    case ADMIN_LIST_ALL_MEMBERS:
      return {
        ...state,
        adminMembers: action.payload,
        loading: false,
      };
    case CREATE_MEMBER:
      return {
        ...state,
        members: [action.payload, ...state.members],
        member: action.payload,
        loading: false,
      };
    case GET_MEMBER:
      const dex = state.members.find((item) => item.id === action.payload.id);
      if (dex > -1) {
        return {
          ...state,
          members: [...state.members],
          member: dex,
          loading: false,
        };
      } else {
        return {
          ...state,
          members: [action.payload, ...state.members],
          member: action.payload,
          loading: false,
        };
      }
    case GET_MEMBER_CLG:
      return {
        ...state,
        memberClg: action.payload,
        loading: false,
      };
    case UPDATE_MEMBER:
      const updatedItems = state.members.map((item) => {
        if (item.id === action.payload.id) {
          return { ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        members: updatedItems,
        member: action.payload,
        loading: false,
      };
    case DELETE_MEMBER:
      return {
        ...state,
        members: state.members.filter((item) => item.id !== action.id),
        loading: false,
      };
    case ADMIN_DELETE_MEMBER:
      return {
        ...state,
        adminMembers: state.adminMembers.filter(
          (item) => item.id !== action.id
        ),
        loading: false,
      };

    case FILTER_BY_ARCHIVE_STATUS:
      return {
        ...state,
        members: state.members.filter(
          (item) => item.is_archived === action.filter
        ),
      };
    case MEMBER_LOADING:
      return {
        ...state,
        loading: action.payload === "false" ? false : true,
      };
    default:
      return state;
  }
};

export default memberReducer;
