import { Switch, Route, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Sidebar from "components/Sidebar";
import Settings from "pages/Settings";
import DashboardAdmin from "pages/admin/DashboardAdmin";
import Footer from "components/Footer";

// Tailwind CSS Style Sheet
import "assets/styles/tailwind.css";
import "app.css";
import Login from "pages/Login";
import PrivateRoute from "components/PrivateRoute";
import AdminPrivateRoute from "components/admin/AdminPrivateRoute";
import { setCurrentReseller } from "actions/authActions";
import Cookies from "js-cookie";
import { checkAuthStatus } from "actions/authActions";
import { checkAdminAuthStatus } from "actions/adminAuthActions";
import Users from "pages/Users";
import CreateEditMember from "pages/CreateEditMember";
import Payments from "pages/Payments";
import NewPayment from "pages/NewPayment";
import AdminLogin from "pages/admin/AdminLogin";
import { setCurrentAdmin } from "actions/adminAuthActions";
import SidebarAdmin from "components/admin/SidebarAdmin";
import AdminUsers from "pages/admin/AdminUsers";
import AdminResellers from "pages/admin/AdminResellers";
import CreateEditReseller from "pages/admin/CreateEditReseller";
import AdminPayments from "pages/admin/AdminPayments";
import CreateMemberEmbeddForm from "components/member/CreateMemberEmbeddForm";
import NotFound from "pages/NotFound";
import CreateEmbeddSuccess from "components/member/CreateEmbeddSuccess";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";

function App() {
  if (window.location.href.indexOf("master") > -1) {
    if (Cookies.get("clgadmin_master_logged_in")) {
      store.dispatch(setCurrentAdmin({ name: "master" }));
      store.dispatch(checkAdminAuthStatus());
    }
  } else {
    if (Cookies.get("clgadmin_logged_in")) {
      store.dispatch(setCurrentReseller({ name: "test" }));
      store.dispatch(checkAuthStatus());
    }
  }

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path={`/`} component={Login} />
            <Route exact path={`/master`} component={AdminLogin} />
            <Route exact path={`/forgot-password`} component={ForgotPassword} />
            <Route
              exact
              path={`/reset-password/:token?/:emailId?`}
              component={ResetPassword}
            />
            <Route
              exact
              path="/user/request/:res_id"
              component={CreateMemberEmbeddForm}
            />
            <Route
              exact
              path="/user/embedd/success"
              component={CreateEmbeddSuccess}
            />
            {/* <Route exact path={`/register`} component={Register} /> */}

            <Route>
              {window.location.href.indexOf("master") > -1 ? (
                <SidebarAdmin />
              ) : (
                <Sidebar />
              )}
              <div className="md:ml-64">
                <Switch>
                  <PrivateRoute exact path="/dashboard" component={Settings} />
                  <PrivateRoute exact path="/settings" component={Settings} />
                  <PrivateRoute exact path="/users" component={Users} />
                  <PrivateRoute exact path="/payments" component={Payments} />
                  <PrivateRoute
                    exact
                    path="/payments/new"
                    component={NewPayment}
                  />
                  <PrivateRoute
                    exact
                    key="create-member"
                    path="/user/new"
                    component={CreateEditMember}
                  />
                  <PrivateRoute
                    exact
                    key="edit-member"
                    path="/user/edit/:id"
                    component={CreateEditMember}
                  />

                  <AdminPrivateRoute
                    exact
                    path="/master/dashboard"
                    component={DashboardAdmin}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/master/resellers"
                    component={AdminResellers}
                  />
                  <AdminPrivateRoute
                    exact
                    key="create-reseller"
                    path="/master/reseller/new"
                    component={CreateEditReseller}
                  />
                  <AdminPrivateRoute
                    exact
                    key="edit-reseller"
                    path="/master/reseller/edit/:id"
                    component={CreateEditReseller}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/master/users"
                    component={AdminUsers}
                  />
                  <AdminPrivateRoute
                    exact
                    path="/master/payments"
                    component={AdminPayments}
                  />
                  {/* <Route path="*" component={NotFound} /> */}
                </Switch>
                <Footer />
              </div>
            </Route>
          </Switch>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
