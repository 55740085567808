import { logout } from "actions/authActions";
import axios from "axios";
import store from "../store";

export default function apiClient() {
  const api = axios.create({
    baseURL: "https://clgadmin.com/clg/public",
    //baseURL: "http://localhost:8000",
    timeout: 10000,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        store.dispatch(logout());

        return Promise.reject();
      }

      return Promise.reject(error);
    }
  );

  return api;
}
