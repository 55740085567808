import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import Input from "@material-tailwind/react/Input";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import { create_member } from "actions/memberActions";
import { resetErrors } from "actions/flashMessagesAction";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { get_member_by_id } from "actions/memberActions";
import { update_member } from "actions/memberActions";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import moment from "moment";
import { renew_request } from "actions/memberActions";

const CreateEditMemberForm = () => {
  const { id } = useParams();
  let history = useHistory();

  const { loading, member, members, memberClg } = useSelector(
    (state) => state.members
  );
  const { reseller } = useSelector((state) => state.auth);
  const errorsSubmission = useSelector((state) => state.errors);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [planType, setPlanType] = useState("annually");
  const [planId, setPlanId] = useState("white_label");
  const [planAmt, setPlanAmt] = useState("828.00");
  const [isMigrate, setIsMigrate] = useState("0");
  const [costCharged, setCostCharged] = useState("0");
  const [selectedMonth, setSelectedMonth] = useState("");

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const get_member = () => dispatch(get_member_by_id(id, history));

  useEffect(() => {
    if (errorsSubmission) {
      setErrors(errorsSubmission);
    }

    const chkMem = members.filter((item) => item.id == id);
    if (chkMem[0]) {
      setFirstName(chkMem[0].first_name ? chkMem[0].first_name : "");
      setLastName(chkMem[0].last_name ? chkMem[0].last_name : "");
      setEmail(chkMem[0].email ? chkMem[0].email : "");
      setUsername(chkMem[0].username ? chkMem[0].username : "");
      setPassword(chkMem[0].password ? chkMem[0].password : "");
      setPhone(chkMem[0].phone ? chkMem[0].phone : "");
      setAddress(chkMem[0].address ? chkMem[0].address : "");
      setCity(chkMem[0].city ? chkMem[0].city : "");
      setState(chkMem[0].state ? chkMem[0].state : "");
      setZip(chkMem[0].zip ? chkMem[0].zip : "");
      setPlanId(chkMem[0].plan_id ? chkMem[0].plan_id : "");
      setPlanType(
        chkMem[0].plan_is_annual && chkMem[0].plan_is_annual == "1"
          ? "annually"
          : "monthly"
      );
      setPlanAmt(
        chkMem[0].plan_is_annual && chkMem[0].plan_is_annual == "1"
          ? "828.00"
          : "89.00"
      );
      setIsMigrate(chkMem[0].is_synced);
    } else if (id && member.length > 0) {
      setFirstName(member[0].first_name ? member[0].first_name : "");
      setLastName(member[0].last_name ? member[0].last_name : "");
      setEmail(member[0].email ? member[0].email : "");
      setUsername(member[0].username ? member[0].username : "");
      setPassword(member[0].password ? member[0].password : "");
      setPhone(member[0].phone ? member[0].phone : "");
      setAddress(member[0].address ? member[0].address : "");
      setCity(member[0].city ? member[0].city : "");
      setState(member[0].state ? member[0].state : "");
      setZip(member[0].zip ? member[0].zip : "");
      setPlanId(member[0].plan_id ? member[0].plan_id : "");
      setPlanType(
        member[0].plan_is_annual && member[0].plan_is_annual == "1"
          ? "annually"
          : "monthly"
      );
      setPlanAmt(
        member[0].plan_is_annual && member[0].plan_is_annual == "1"
          ? "828.00"
          : "89.00"
      );
      setIsMigrate(member[0].is_synced);
    }
  }, [errorsSubmission, id, dispatch, member.length]);

  useEffect(() => {
    if (id) {
      get_member();
    }
    dispatch(resetErrors());
  }, []);

  let loader;
  if (loading) {
    loader = <Loader />;
  }

  const getRenewCost = (e) => {
    const cost = e.target.value * planAmt;
    setCostCharged(cost);
    setSelectedMonth(e.target.value);
  };

  const saveForm = async (e) => {
    e.preventDefault();
    const data = {
      id: id ? id : "",
      firstName,
      lastName,
      email,
      username,
      password,
      phone,
      address,
      city,
      state,
      zip,
      planType,
      planId,
    };
    console.log(data);
    !id
      ? dispatch(create_member(data, history))
      : dispatch(update_member(data, history));
  };

  const extendRenewForm = async (e) => {
    e.preventDefault();
    const data = {
      userEmail: e.target[0].value,
      months: e.target[1].value,
      totalCost: e.target[2].value,
      planAmt,
      planId,
      planType,
      id,
    };
    dispatch(renew_request(data, history));
  };

  let clgStatus;
  const totalProps = Array.isArray(memberClg)
    ? memberClg.reduce((a, obj) => a + Object.keys(obj).length, 0)
    : 0;

  if (!loading) {
    if (id) {
      if (Object.keys(memberClg).length !== 0) {
        const today = moment().format("YYYY-MM-DD");
        const past = moment(memberClg.subExpiryDate).format("YYYY-MM-DD");

        if (moment(today).isBefore(past)) {
          clgStatus = (
            <div>
              <p style={{ fontSize: "large", color: "green" }}>
                The user subscription will expire on{" "}
                <strong>
                  {moment(memberClg.subExpiryDate).format("MMMM Do YYYY")}
                </strong>
                . Would you want to extend it?
              </p>
              <Button
                color="orange"
                buttonType="filled"
                size="regular"
                type="button"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="light"
                onClick={(e) => OpenExtendForm()}
              >
                Renew or Extend Subscription
              </Button>
            </div>
          );
        } else {
          clgStatus = (
            <div>
              <p style={{ fontSize: "large", color: "red" }}>
                The user subscription has expired on{" "}
                <strong>
                  {moment(memberClg.subExpiryDate).format("MMMM Do YYYY")}
                </strong>
                . Would you like to renew it?
              </p>
              <Button
                color="orange"
                buttonType="filled"
                size="regular"
                type="button"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="light"
                onClick={(e) => OpenExtendForm()}
              >
                Renew or Extend Subscription
              </Button>
            </div>
          );
        }
      } else {
        clgStatus = (
          <p style={{ fontSize: "large", color: "red" }}>
            Sorry, we couldn't find this user on CLGAPP.
          </p>
        );
      }
    }
  }

  function OpenExtendForm() {
    setShowModal(true);
    setCostCharged("0");
    setSelectedMonth("");
  }
  console.log(isMigrate);
  return (
    <Card>
      <CardHeader color="purple" contentPosition="none">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">
            {!id ? "Create User" : "Edit User"}
          </h2>
          <Link
            to="/users"
            style={{
              padding: "5px 15px",
              border: "1px solid",
              borderRadius: "5px",
            }}
          >
            All Users
          </Link>
        </div>
      </CardHeader>
      <CardBody>
        <form onSubmit={saveForm}>
          <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
            User Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="First Name"
                value={firstName && firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.firstName !== "undefined"
                    ? errors.errors.firstName[0]
                    : ""
                }
              />
            </div>
            <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Last Name"
                value={lastName && lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-6/12 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Username"
                value={username && username}
                onChange={(e) => setUsername(e.target.value)}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.username !== "undefined"
                    ? errors.errors.username[0]
                    : ""
                }
              />
              <p className="pt-2" style={{ color: "red", fontSize: "13px" }}>
                Useful in case you decide to migrate this user to clpapp.
              </p>
            </div>
            <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
              <Input
                color="purple"
                placeholder="Password"
                type="password"
                value={password && password}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.password !== "undefined"
                    ? errors.errors.password[0]
                    : ""
                }
                onChange={(e) => setPassword(e.target.value)}
              />
              <p className="pt-2" style={{ color: "red", fontSize: "13px" }}>
                Useful in case you decide to migrate this user to clpapp.
              </p>
            </div>
            <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
              <Input
                type="email"
                color="purple"
                placeholder="Email Address"
                value={email && email}
                onChange={(e) => setEmail(e.target.value)}
                error={
                  typeof errors.errors !== "undefined" &&
                  typeof errors.errors.email !== "undefined"
                    ? errors.errors.email[0]
                    : ""
                }
              />
            </div>
          </div>

          <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            Contact Information
          </h6>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-12/12 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Address"
                value={address && address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-3/12 pr-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Phone"
                value={phone && phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-3/12 px-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="City"
                value={city && city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="State"
                value={state && state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="w-full lg:w-3/12 pl-4 mb-10 font-light">
              <Input
                type="text"
                color="purple"
                placeholder="Zip"
                value={zip && zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </div>
          </div>
          <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
            Plan Information
          </h6>
          <p style={{ color: "red", fontStyle: "italic" }}>
            {isMigrate === 1
              ? "The user has been migrated already, you cannot change plan information."
              : ""}
          </p>
          <div className="flex flex-wrap mt-10">
            <div className="w-full lg:w-6/12 mb-10 font-light">
              <div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    checked={planType === "monthly"}
                    disabled={isMigrate && isMigrate === 1}
                    value="monthly"
                    onChange={(e) => {
                      setPlanType(e.target.value);
                      setPlanAmt("89.00");
                    }}
                  />

                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="inlineRadio10"
                  >
                    Monthly
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    value="annually"
                    disabled={isMigrate && isMigrate === 1}
                    checked={planType === "annually"}
                    onChange={(e) => {
                      setPlanType(e.target.value);
                      setPlanAmt("828.00");
                    }}
                  />

                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="inlineRadio20"
                  >
                    Annually
                  </label>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-12/12 mb-10 font-light">
              <div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="sub_plan"
                    value="white_label"
                    checked={planId === "white_label"}
                    onChange={(e) => setPlanId(e.target.value)}
                  />

                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="inlineRadio20"
                  >
                    White Label (${planAmt})
                  </label>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-12/12 mb-10 font-light">
              {clgStatus}
            </div>
          </div>

          <Button
            color="purple"
            buttonType="filled"
            type="submit"
            size="regular"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
          >
            Submit
          </Button>
          {loader && loader}
        </form>
      </CardBody>
      <Modal
        active={showModal}
        toggler={() => setShowModal(false)}
        style={{ width: "35rem" }}
      >
        <ModalHeader
          toggler={() => setShowModal(false)}
          style={{ fontSize: "1.2rem" }}
        >
          Renew or Extend User Subscription
        </ModalHeader>
        <ModalBody>
          <div className="text-base leading-relaxed text-gray-600 font-normal renewModal">
            <div>
              {typeof errors.errors !== "undefined" &&
              typeof errors.errors.months !== "undefined" ? (
                <p style={{ color: "red", fontStyle: "italic" }}>
                  {errors.errors.months[0]}
                </p>
              ) : (
                ""
              )}
              {typeof errors.errors !== "undefined" &&
              typeof errors.errors.api_error !== "undefined" ? (
                <p style={{ color: "red", fontStyle: "italic" }}>
                  {errors.errors.api_error}
                </p>
              ) : (
                ""
              )}
              <form onSubmit={extendRenewForm}>
                <input type="hidden" name="userEmail" value={email} />
                <div className="flex flex-wrap mt-10">
                  <div className="w-full lg:w-4/12 pr-4 mt-2 font-light">
                    <select value={selectedMonth} onChange={getRenewCost}>
                      <option value="">--Select Month--</option>
                      <option value="1">1 Month(30 days)</option>
                      <option value="2">2 Month(60 days)</option>
                      <option value="3">3 Month(90 days)</option>
                      <option value="4">4 Month(120 days)</option>
                      <option value="5">5 Month(150 days)</option>
                      <option value="6">6 Month(180 days)</option>
                      <option value="7">7 Month(210 days)</option>
                      <option value="8">8 Month(240 days)</option>
                      <option value="9">9 Month(270 days)</option>
                      <option value="10">10 Month(300 days)</option>
                      <option value="11">11 Month(330 days)</option>
                      <option value="12">12 Month(360 days)</option>
                    </select>
                  </div>

                  <div className="w-full lg:w-4/12 pr-4 font-light">
                    <Input
                      type="text"
                      color="purple"
                      placeholder="Total credits charged"
                      value={costCharged && costCharged}
                      className="costCharged"
                      readOnly="readOnly"
                      error={
                        typeof errors.errors !== "undefined" &&
                        typeof errors.errors.firstName !== "undefined"
                          ? errors.errors.firstName[0]
                          : ""
                      }
                    />
                  </div>
                  <div className="w-full lg:w-4/12 pr-4 font-light">
                    <Button
                      color="orange"
                      buttonType="filled"
                      className="mt-3"
                      size="regular"
                      type="submit"
                      rounded={false}
                      block={false}
                      iconOnly={false}
                      ripple="light"
                    >
                      Renew or Extend
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {reseller.discountPer && (
            <p style={{ fontStyle: "italic", color: "red", fontSize: "13px" }}>
              * A discount of {reseller.discountPer}% will be implemented to
              your total credits charged.
            </p>
          )}
          <Button
            color="red"
            buttonType="link"
            onClick={(e) => setShowModal(false)}
            ripple="dark"
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default CreateEditMemberForm;
