import apiClient from "utils/apiClient";
import Cookies from "js-cookie";

import {
  GET_ERRORS,
  RESELLER_AUTH_LOADING,
  SET_CURRENT_RESELLER,
} from "./actionTypes";
import { addFlashMessages } from "./flashMessagesAction";

export const login = (data, history) => (dispatch) => {
  dispatch(setAuthLoading());

  apiClient()
    .get("/sanctum/csrf-cookie")
    .then(() => {
      apiClient()
        .post(`/login`, data)
        .then(function (response) {
          if (response.status === 200 || response.status === 204) {
            onLogin();
            dispatch(setCurrentReseller(response.data.user));
            history.push("/dashboard");
          }
        })
        .catch((err) => {
          typeof err.response !== "undefined" &&
            dispatch({ type: GET_ERRORS, payload: err.response.data });
          dispatch(setAuthLoading("false"));
        });
    });
};
export const forgot_password = (email) => (dispatch) => {
  dispatch(setAuthLoading());
  apiClient()
    .get("/sanctum/csrf-cookie")
    .then(() => {
      apiClient()
        .post(`/api/reseller/forgot`, email)
        .then((response) => {
          dispatch(
            addFlashMessages({
              type: "success",
              eventFor: "reseller_forgot",
              text: response.data.message,
            })
          );
          dispatch(setAuthLoading("false"));
          //history.push("/master/resellers");
        })
        .catch((err) => {
          typeof err.response !== "undefined" &&
            dispatch({ type: GET_ERRORS, payload: err.response.data });
          dispatch(setAuthLoading("false"));
        });
    });
};
export const reset_password = (data, history) => (dispatch) => {
  dispatch(setAuthLoading());
  apiClient()
    .get("/sanctum/csrf-cookie")
    .then(() => {
      apiClient()
        .post(`/api/reseller/reset-password`, data)
        .then((response) => {
          dispatch(
            addFlashMessages({
              type: "success",
              eventFor: "reset_pass",
              text: response.data.message,
            })
          );
          dispatch(setAuthLoading("false"));
          setTimeout(() => {
            history.push("/");
          }, 4000);
        })
        .catch((err) => {
          typeof err.response !== "undefined" &&
            dispatch({ type: GET_ERRORS, payload: err.response.data });
          dispatch(setAuthLoading("false"));
        });
    });
};
export const register = (data, history) => (dispatch) => {
  dispatch(setAuthLoading());

  apiClient()
    .get("/sanctum/csrf-cookie")
    .then(() => {
      apiClient()
        .post(`/register`, data)
        .then(function (response) {
          if (response.status === 201) {
            onRegister();
            dispatch(
              addFlashMessages({
                type: "success",
                eventFor: "reseller_account_signup",
                text:
                  "Thanks for signing up. Welcome to our community. We are happy to have you on board. Please wait... while we take you to your dashboard.",
              })
            );
            setTimeout(() => {
              history.push("/dashboard");
              apiClient()
                .get("/api/user")
                .then((res) => {
                  dispatch(setCurrentReseller(res.data));
                });
            }, 4000);
          }
        })
        .catch((err) => {
          typeof err.response !== "undefined" &&
            dispatch({ type: GET_ERRORS, payload: err.response.data });
          dispatch(setAuthLoading("false"));
        });
    });
};

export const logout = () => (dispatch) => {
  apiClient()
    .post("/logout")
    .then(function (response) {
      if (response.status === 204) {
        Cookies.remove("clgadmin_logged_in");
        dispatch(setCurrentReseller({}));
      }
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

export const update_reseller = (data) => (dispatch) => {
  dispatch(setAuthLoading());
  apiClient()
    .put("api/reseller/update", data)
    .then((response) => {
      dispatch(setCurrentReseller(response.data));
      dispatch(
        addFlashMessages({
          type: "success",
          eventFor: "reseller_account_setting_update",
          text: response.data.message,
        })
      );
    })
    .catch((err) => {
      typeof err.response !== "undefined" &&
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      dispatch(setAuthLoading("false"));
    });
};

//Set logged member
export const setCurrentReseller = (reseller) => {
  return {
    type: SET_CURRENT_RESELLER,
    payload: reseller,
  };
};

const setAuthLoading = (show = "true") => {
  return {
    type: RESELLER_AUTH_LOADING,
    payload: show,
  };
};

const onLogin = () => {
  const insixtyMinutes = new Date(new Date().getTime() + 120 * 60 * 1000);
  console.log(insixtyMinutes);
  Cookies.set("clgadmin_logged_in", true, { expires: insixtyMinutes });
};
const onRegister = async () => {
  const insixtyMinutes = new Date(new Date().getTime() + 120 * 60 * 1000);
  console.log(insixtyMinutes);
  Cookies.set("clgadmin_logged_in", true, { expires: insixtyMinutes });
  const res = await apiClient().post(`/api/reseller/sync`);
  console.log(res);
};
export const checkAuthStatus = () => (dispatch) => {
  dispatch(setAuthLoading());
  apiClient()
    .get("/api/user")
    .then((res) => {
      dispatch(setCurrentReseller(res.data));
    })
    .catch((err) => {
      Cookies.remove("clgadmin_logged_in");
      dispatch(setCurrentReseller({}));
    });
};
